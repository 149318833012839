import "react-toastify/dist/ReactToastify.css";
import empty from "../../core/assets/empty.svg";
import { Box } from "@material-ui/core";
import { toast } from "react-toastify";

export const showToast = (message: string, type = "error") => {
  const options = {
    autoClose: 1000,
  };
  switch (type) {
    case "error":
      toast.error(message, options);
      break;
    case "success":
      toast.success(message, options);
      break;
    default:
      toast(message, options);
  }
};
export const EmptyDataImage = ({className}:{className?: any}) => {
  return (
    <Box
      component="div"
      sx={{
        minWidth: "100%",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        height: "none",
      }}
      className={className || ""}
    >
      <Box
        component="img"
        alt="No Data Founded"
        src={empty}
        draggable={false}
      />
    </Box>
  );
};
